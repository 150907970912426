<template>
    <div>
        <div class="wz">
            <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>大数据管理</el-breadcrumb-item>
            <el-breadcrumb-item>重点企业数据</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="box">
            <!-- <div class="topchange">
                <el-input v-model="num" placeholder="请输入一个数字" style="width:200px;margin-right:5px;"  onkeyup="value=value.replace(/[^\d\.]/g,'')" ></el-input><el-button type="primary"  @click="changedata">更改数据</el-button><el-button type="primary"  @click="resetdata">重置数据</el-button>
            </div> -->
            <div class="list" >
                <div class="listtitle" style="height:50px;"><strong>重点企业数据</strong><el-button type="primary" icon="el-icon-circle-plus-outline" style="float:right;" @click="addshow=true">添加记录</el-button></div>
                <div class="listitem" >
                    <el-table
                    :data="list"
                    border
                    :header-cell-style="{'font-weight':'bold','color':'#555555','padding':'6px 0'}"
                    stripe
                    style="width: 100%">
                        <el-table-column
                        prop="name"
                        width="280"
                        label="企业名称">
                        </el-table-column>
                        <el-table-column
                        prop="endEmpNumber"
                        label="截止上报日员工数">
                        </el-table-column>
                        <el-table-column
                        prop="endYearNumber"
                        label="年底员工数">
                        </el-table-column>
                        <el-table-column
                        prop="allYearNumber"
                        label="全年缺工数">
                        </el-table-column>
                        <el-table-column
                        prop="alreadyNumber"
                        label="本年截至上报日已招员工数">
                        </el-table-column>
                        <el-table-column
                        prop="lastNumber"
                        label="截至上报日缺工人数">
                        </el-table-column>
                        <el-table-column
                        prop="partitionTitle"
                        width="160"
                        label="操作">
                            <template slot-scope="scope">
                                <el-button type="primary"  @click="gotoEdit(scope.row)">编辑</el-button>
                                <el-button type="danger"  @click="deleteind(scope.row)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <pop-up title="添加" width="26%" :isshow="addshow" :haveconfirm="true" @close="getclose" @confirm="getconfirm">
            <div slot="box">
                <el-form ref="addfrom" :model="addfrom" label-width="130px">
                    <el-form-item label="企业名称">
                        <el-input v-model="addfrom.name"></el-input>
                    </el-form-item>
                    <el-form-item label="截止上报日员工数">
                        <el-input v-model="addfrom.endEmpNumber"></el-input>
                    </el-form-item>
                    <el-form-item label="年底员工数">
                        <el-input v-model="addfrom.endYearNumber"></el-input>
                    </el-form-item>
                    <el-form-item label="全年缺工数">
                        <el-input v-model="addfrom.allYearNumber"></el-input>
                    </el-form-item>
                    <el-form-item label="本年截至上报日已招员工数">
                        <el-input v-model="addfrom.alreadyNumber"></el-input>
                    </el-form-item>
                    <el-form-item label="截至上报日缺工人数">
                        <el-input v-model="addfrom.lastNumber"></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </pop-up>
        <pop-up title="编辑" width="26%" :isshow="editshow" :haveconfirm="true" @close="getclose2" @confirm="getconfirm2">
            <div slot="box">
                <el-form ref="addfrom" :model="editform" label-width="130px">
                    <el-form-item label="企业名称">
                        <el-input v-model="editform.name"></el-input>
                    </el-form-item>
                    <el-form-item label="截止上报日员工数">
                        <el-input v-model="editform.endEmpNumber"></el-input>
                    </el-form-item>
                    <el-form-item label="年底员工数">
                        <el-input v-model="editform.endYearNumber"></el-input>
                    </el-form-item>
                    <el-form-item label="全年缺工数">
                        <el-input v-model="editform.allYearNumber"></el-input>
                    </el-form-item>
                    <el-form-item label="本年截至上报日已招员工数">
                        <el-input v-model="editform.alreadyNumber"></el-input>
                    </el-form-item>
                    <el-form-item label="截至上报日缺工人数">
                        <el-input v-model="editform.lastNumber"></el-input>
                    </el-form-item>
                    
                </el-form>
            </div>
        </pop-up>
    </div>
</template>
<script>
import serverurl from '@/api/bigdata'
import _api from '@/api/index'
export default {
    data(){
        return{
            lefttop:[],
            num:"",
            list:[],
            addshow:false,
            editshow:false,
            addfrom:{
                name:"",
                endEmpNumber:"",
                endYearNumber:"",
                allYearNumber:"",
                alreadyNumber:"",
                lastNumber:"",
            },
            editform:{
                id:"",
                name:"",
                endEmpNumber:"",
                endYearNumber:"",
                allYearNumber:"",
                alreadyNumber:"",
                lastNumber:"",
            }
        }
    },
    created(){
        this.getList()
    },
    methods:{
        resetdata(){
            _api.get(serverurl.reset4,{}).then(res=>{
                console.log(res)
                if(res.success){
                    this.$message.success("重置成功！")
                    this.getList()
                }
            })
        },
        changedata(){//更改数据
            if(this.num==""){
                this.$message.error("请输入一个变换系数")
                return
            }
            _api.get(serverurl.random4,{num:this.num}).then(res=>{
                console.log(res)
                if(res.success){
                    this.$message.success("更改成功！")
                    this.getList()
                }
            })
        },
        getList(){
            _api.get(serverurl.potionmember,{}).then(res=>{
                console.log(res)
                if(res.success){
                    this.list=res.data
                }
            })
        },
        deleteind(row){
            _api.get(serverurl.potionmemberdelete+"/"+row.id).then(res=>{
                if(res.success){
                    this.$message.error("删除成功！")
                    this.getList()
                }
            })
        },
        gotoEdit(row){
            
            for( let k in this.editform){
                this.editform[k]=row[k]
            }
            this.editshow=true
        },
        getclose2(){
            this.editshow=false
        },
        getconfirm2(){
            _api.put(serverurl.potionmember,this.editform).then(res=>{
                console.log(res)
                this.editshow=false
                if(res.success){
                    this.$message.success("编辑成功！")
                    this.editform={
                        id:"",
                        name:"",
                        endEmpNumber:"",
                        endYearNumber:"",
                        allYearNumber:"",
                        alreadyNumber:"",
                        lastNumber:"",
                    }
                    this.getList()
                }
            })
        },
        getclose(){
            this.addshow=false
        },
        getconfirm(){
            _api.post(serverurl.potionmember,this.addfrom).then(res=>{
                this.addshow=false
                if(res.success){
                    this.$message.success("添加成功！")
                    this.addfrom={
                        name:"",
                        endEmpNumber:"",
                        endYearNumber:"",
                        allYearNumber:"",
                        alreadyNumber:"",
                        lastNumber:"",
                    }
                    this.getList()
                }
            })
        }
    }
}
</script>
<style scoped>
.topchange{ text-align: center; padding-bottom: 20px;}
</style>